<template>
  <BaseInfobox
    type="error"
    remove-margins
  >
    {{ $t('clientError.useEntireLink') }}
  </BaseInfobox>
</template>

<script>
import BaseInfobox from '@/components/BaseInfobox.vue'

export default {
  name: 'HomePage',
  components: {
    BaseInfobox
  }
}
</script>
